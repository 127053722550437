<template>
  <div>
    <jzsy-header title="修改密码"></jzsy-header>
    <div class="wrapper">
      <ValidationObserver ref="form">
        <ValidationProvider rules="required" name="旧密码" v-slot="{ errors }">
          <md-input-item class="input-item" type="password" placeholder="旧密码" v-model="form.oldPassword"
              is-title-latent :maxlength="16" is-highlight title="旧密码" :error="errors[0]" :disabled="loading">
              <md-icon name="security" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <ValidationProvider rules="required" name="新密码" v-slot="{ errors }">
          <md-input-item class="input-item" type="password" placeholder="新密码" v-model="form.newPassword"
              is-title-latent :maxlength="16" is-highlight title="新密码" :error="errors[0]" :disabled="loading">
              <md-icon name="security" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <ValidationProvider rules="required|confirmed:新密码" name="确认密码" v-slot="{ errors }">
          <md-input-item class="input-item" type="password" placeholder="再输一次新密码" v-model="form.confirmPassword"
              is-title-latent :maxlength="16" is-highlight title="确认密码" :error="errors[0]" :disabled="loading">
              <md-icon name="security" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <md-button class="submit-btn" type="primary" size="small" :plain="true" :loading="loading" @click="updateHandle">修改</md-button>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import LoginApi from '@/api/login'
import { Toast } from 'mand-mobile'

export default {
  data() {
    return {
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      loading: false,
    }
  },
  methods: {
    updateHandle() {
      this.$refs.form.validate().then(res => {
        if (res) {
          this.loading = true
          LoginApi.updatePassword(this.form).then(() => {
              Toast.succeed('修改成功')
            setTimeout(() => {
              this.$router.push('/')
            }, 500)
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .right-nav-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92px;
    margin-right: 30px;
  }
  .wrapper {
    padding: 50px;
  }
  .submit-btn {
    margin-top: 50px;
  }
</style>